import { useState, useEffect, useRef } from "react";
import { Alert, Button, GeolocationInput } from "../../components"
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { useClientConfigs } from "../../contexts/ClientConfigContext";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import {  updateQuote } from "../../store/quote/action";
import { GeicoPageNames, useAdobeAnalytics } from "../../contexts/AdobeAnalyticsContext";
import { StateLookupRequest, STATE_LOOKUP_RESPONSE } from "../../api/types";
import { digitsOnly } from "../../lib/text-helpers";
import { useApi } from "../../api";
import { useAutoRedirectContext } from "../../contexts/AutoRedirectContext";
import { BASE_APP_PAth, API_ERROR_MESSAGE } from "../../api/launchpad-api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { storage } from "../../lib/storage";

export const ZipSelection = () => {
    const api = useApi(); 
    const navigate = useNavigate();   
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: "", message: "" });
    const quote = useSelector((state: RootState) => state.quote.quote);
    const { showLoader, hideLoader } = usePageLoadingContext();
    const clientConnfig = useClientConfigs();
    const { geicoDtm, setGeicoDtm } = useAdobeAnalytics();
    const [searchParams] = useSearchParams();
    const prefilledZipCode = searchParams.get("zip");
    const zipCode = quote.data.Zip;
    const [ model, setModel ] = useState({ zip: quote.data.Zip, stateCode: quote.data.StateCode });
    const unitedState = quote.data.StateCode;
    const [zipCodeError, setZipCodeError] = useState("");
    const refZipCodeUseRef = useRef<HTMLInputElement>();
    const redirectContext = useAutoRedirectContext();
    const zipSelectionPageName = GeicoPageNames.Launchpad + ":Zip-Selection";

    useEffect(() => {
        const initialZip = prefilledZipCode || (storage.exist('searchQuoteZip') ? storage.load('searchQuoteZip') : "");
        quote.data.Zip = initialZip;
        setModel({ zip: initialZip, stateCode: quote.data.StateCode });
    }, []);
    
    useEffect(() => {
        try {
            showLoader();
            setGeicoDtm({ ...geicoDtm, viewName: zipSelectionPageName });
            setModel({ ...model, zip: quote.data.Zip, stateCode: quote.data.StateCode });
        }
        catch (err) {
            console.error(err);
            hideLoader();
        }
    }, []);

    useEffect(() => {
        if (redirectContext.verificationDone) {
            hideLoader();
        }      
    }, [redirectContext.verificationDone])

    useEffect(() => {
        if (redirectContext.verificationDone) {
            stateLookup();
        }
    }, [zipCode]);

    useEffect(() => {
        if (redirectContext.verificationDone) {
            // Adobe Geico DTM update zip state         
            setGeicoDtm({
                ...geicoDtm,
                zip: quote.data.Zip,
                state: quote.data.StateCode,
                viewName: zipSelectionPageName
            });
        }
    }, [quote.data.StateCode]);

    const setZipCode = (code: string): void => {
        const quoteData = { ...quote.data, Zip: code };
        store.dispatch(updateQuote(quoteData));
    }

    const setUnitedState = (code: string): void => {
        const quoteData = { ...quote.data, StateCode: code };
        store.dispatch(updateQuote(quoteData));
    }

    const onBlurZipCode = (value: string) => {
        let cleanedValue = digitsOnly(value);
        if (cleanedValue.length < 5) {
            setZipCodeError("Please enter a valid zip code.");
            setUnitedState("");
            setZipCode("");
        }
        else {
            if (zipCode === "" && unitedState === "") {
                setZipCode(cleanedValue);
            }
        }
    };

    const isNextButtonDisabled = (): boolean => {
        return quote.data.Zip === undefined
            || quote.data.Zip === ""
            || quote.data.Zip.length !== 5
            || quote.data.StateCode === undefined
            || quote.data.StateCode === ""
            || quote.data.StateCode.length !== 2;
    }

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }

    const stateLookup = async () => {
        clearAlertMessage();
        if (zipCode.length === 5) {
            let request: StateLookupRequest = { zipCode: zipCode };
            showLoader();
            await api.commercialAgentApi.stateLookup(request)
                .then(response => {                   
                    if (response.type !== STATE_LOOKUP_RESPONSE) {
                        window.scrollTo(0, 0);                
                        setShowAlert(true);
                        setAlertMessage({
                            title: "Thank you for your request.",
                            message: API_ERROR_MESSAGE
                        });                                              
                    }
                    else {
                        if (!response.data.success) {
                            var err = response.data.errorMessage === null || response.data.errorMessage === ""
                                ? "Please enter a valid zip code." : response.data.errorMessage;                            
                            setZipError(err);
                        }
                        else {
                            setZipCodeError("");
                            setUnitedState(response.data.state);
                        }
                    }
                    hideLoader();
                }, (error) => {
                    console.error(error);                    
                    hideLoader();
                });
        }
        else {
            // Clear Zip and State
            clearZipError();
        }
    };

    function setZipError(errorMessage: string) {
        window.scrollTo(0, window.screenTop);
        refZipCodeUseRef.current?.focus();
        setZipCodeError(errorMessage);
        setUnitedState("");
        hideLoader();
    }

    function clearZipError() {
        setZipCodeError("");
        setUnitedState("");        
    }

    function onNext(): void {
        showLoader();
        store.dispatch(updateQuote({
            ...quote.data,
            Zip : quote.data.Zip,
            StateCode : quote.data.StateCode, 
        }));     

        navigate(BASE_APP_PAth + "/product-selection");
    }

    return (
        <div className="container" style={{ padding: "1rem", width: "95%" }}>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    {(showAlert && (alertMessage.title || alertMessage.message) &&
                        <Alert
                            title={alertMessage.title}
                            content={alertMessage.message}
                            closeAlert={() => clearAlertMessage()}
                        />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <h2 className="page-header-left" style={{ padding: "1rem", marginBottom: "1rem" }}>
                        What's your business ZIP code?
                    </h2>
                </div>
            </div>
            <div className="row" >
                <div className="col-sm-12 col-md-12">
                    <GeolocationInput
                        label="5-Digit ZIP Code"
                        type="text"
                        className="w-1/5 sm:w-2/5 xs:w-full"
                        containerClass="col-md-12"
                        style={{ marginTop: ".1rem", marginBottom: "0rem" }}
                        labelClass="text"
                        maxLength={5}
                        value={zipCode}
                        onBlur={(e) => onBlurZipCode(e.target.value)}
                        onChange={(e) => {
                            let cleanedValue = digitsOnly(e.target.value);
                            setZipCode(cleanedValue);
                        }}
                        geoLocate={() => {
                            if (refZipCodeUseRef.current) {
                                setZipCode(refZipCodeUseRef.current?.value);
                            }
                        }}
                        innerRef={refZipCodeUseRef}
                        autoFocus
                        error={zipCodeError}
                    />
                </div>
            </div>
            <div className="row mt-10" >
                <div className="col-sm-12 col-md-10">
                    <div className="privacy-container">
                        <img style={{ width: "20px", height:"20px" }} src={process.env.PUBLIC_URL + "/assets/CoverageIconImage.png"} />
                        <div className="privacy-header">
                            <h5>Your Privacy Is Important to Us</h5>
                            <div className="privacy-content">
                                We value your privacy and will never sell your information. Your data is safe with GEICO.
                            </div>
                        </div>
                        
                    </div>
                </div>
             </div>
            <div className="row mt-10" >
                <div className="col-sm-12 col-md-6">
                    <Button style={{ display: "none" }}
                        className="btn--secondary nav-button-back mt-10 w-3/5 xs:w-full sm:w-full"
                        children="Back"
                        onClick={(e) => window.location.href = clientConnfig.geicoAppUrl}
                    />
                </div>

                <div className="col-sm-12 col-md-6">
                    <Button disabled={isNextButtonDisabled()}
                        className="btn--primary nav-button-next mt-10  w-3/5 xs:w-full sm:w-full"                       
                        children="Continue"
                        onClick={(e) => onNext()}
                    />
                </div>
            </div>
        </div>
    );
};
