import React, { useEffect, useState } from 'react';
import { useClientConfigs } from './ClientConfigContext';

const MAX_RETRY_COUNT = 5;

export type IGeicoDtm = {
    application: string,
    UIVersion: string,
    viewName: string,
    language: string,
    product?: string,
    zip?: string,
    state?: string,
    crossSellType?: string,
    crossSellOffer?: string,
    serviceMilestone: Array<string>,
    launchpadId: string
}

declare global {
    interface Window {
        geicoDtm: IGeicoDtm,
        _satellite: any | undefined;
    }
}

export class GeicoPageNames {
    static GuidedUnGuided: string= "Sales:Commercial:Quote:GuidedUnGuided";
    static Launchpad: string= "Sales:Commercial:Quote:Launchpad";
    static Questions: string= "Sales:Commercial:Quote:GuidedQuestions";
}

const defaultGeicoDtm: IGeicoDtm = {
    application: "y",
    UIVersion: "React",
    viewName: "",
    language: "dtm-en-us",
    product: "",
    zip: "",
    state: "",
    crossSellType: "",
    crossSellOffer: "",
    serviceMilestone:[],
    launchpadId: ""
};

const sendGeicoDtm = (retryCount: number =1, submitForm: boolean) => {       
    if (retryCount >= MAX_RETRY_COUNT) {
        console.log("Fail to call sendGeicoDtm->updateData Reason:-Timeout.");
        return;
    }
  
    if (window._satellite !== null && window._satellite !== undefined && typeof(window._satellite.track) === "function") {
        // Set geicoDtmModel to window.geicoDtm and call track       
        // redirectZip will only be called after user selects keyword and clicks continue on business-keyword page, 
        //where submitForm is set to True
        if(submitForm){
            window._satellite.track("redirectZip"); 
        }      
        window._satellite.track("updateData");
    }
    else {
        setTimeout(() => {
            console.log("sendGeicoDtm:updateData Retry count: " + retryCount);
            sendGeicoDtm(++retryCount, submitForm);
        }, 2000)
    }
}

interface IAdobeAnalyticsContext {
    geicoDtm: IGeicoDtm,
    setGeicoDtm: (geicoDtm: IGeicoDtm) => void,
    sendToAdobe: (formSubmit: boolean) => void
}

const AdobeAnalyticsContext = React.createContext<IAdobeAnalyticsContext>({
    geicoDtm: defaultGeicoDtm,
    setGeicoDtm: (geicoDtm: IGeicoDtm): void => { },
    sendToAdobe: (formSubmit: boolean): void => { }
});

type Props = {
       children: React.ReactNode;
};

const AdobeAnalyticsContextProvider: React.FC<Props> = ({children}) => {

    const [geicoDtmModel, setGeicoDtmModel] = useState<IGeicoDtm>(defaultGeicoDtm);

    const clientConfigs = useClientConfigs();

    // Update window.geicoDtm
    const updateGeicoDtm = (geicoDtm: IGeicoDtm):void => {

        if (!window.geicoDtm) {
            window.geicoDtm = defaultGeicoDtm;
        }           
        window.geicoDtm.application = geicoDtm.application;
        window.geicoDtm.UIVersion = geicoDtm.UIVersion;
        window.geicoDtm.viewName = geicoDtm.viewName;
        window.geicoDtm.language = geicoDtm.language;
        window.geicoDtm.product = geicoDtm.product;
        window.geicoDtm.zip = geicoDtm.zip;
        window.geicoDtm.state = geicoDtm.state;
        window.geicoDtm.crossSellType = geicoDtm.crossSellType;
        window.geicoDtm.crossSellOffer = geicoDtm.crossSellOffer;
        window.geicoDtm.serviceMilestone = geicoDtm.serviceMilestone; 
        window.geicoDtm.launchpadId = geicoDtm.launchpadId;

        setGeicoDtmModel(geicoDtm);
    }

    useEffect(() => {
        if (clientConfigs.adobeAnalyticsUri) {
            try {
                window._satellite = undefined;               
                console.debug(clientConfigs.adobeAnalyticsUri);
                const script = document.createElement("script");
                script.src = clientConfigs.adobeAnalyticsUri;
                document.head.appendChild(script);              
            }
            catch (error) {
                console.error("Unable to start adobe analytics");
            }
        }
    }, [clientConfigs]);

    // Send track call with 1 retry
    function sentDtmToAdobe(formSubmit: boolean) {       
        sendGeicoDtm(1, formSubmit); // Retry Count 1
    }   

    return (
        <AdobeAnalyticsContext.Provider value={{ geicoDtm: geicoDtmModel, sendToAdobe: sentDtmToAdobe, setGeicoDtm: updateGeicoDtm }}>
            {children}
        </AdobeAnalyticsContext.Provider>
    );
}

const useAdobeAnalytics = (): IAdobeAnalyticsContext => {

    const context = React.useContext(AdobeAnalyticsContext);
        
    if (!context) {
        throw new Error(
            "useAdobeAnalytics must be used within a AdobeAnalyticsContextProvider"
        );
    }

    return context;
}

export { AdobeAnalyticsContextProvider, AdobeAnalyticsContext, useAdobeAnalytics };